import React from "react";
import "./CheckoutSeven11.scss";
import CheckoutTitle from "./CheckoutTitle";
import "../../comp/BarcodeAndPDF.scss";
var Barcode = require("react-barcode");

function CheckoutSeven11({ data }) {
  const getExpiredTime = () => {
    return new Date(Date.now() + Number(data?.expired) * 1000).toLocaleString();
  };
  return (
    <div className="checkoutSeven11">
      <CheckoutTitle />
      <div className="checkoutSeven11__inst">
        <p className="checkoutSeven11__instTitle">STEP 1: FIND NEAREST 7-11</p>
        <p className="checkoutSeven11__instContent">
          1. Visit your nearest 7-11 stores before the time on the invoice runs out
        </p>
        <p className="checkoutSeven11__instContent">2. Let the cashier scan the barcode below</p>
        <p className="checkoutSeven11__instTitle">STEP 2: PAYMENT DETAILS</p>
        <p className="checkoutSeven11__instContent">
          1. Present the payment code on the invoice to the cashier and confirm the amount is correct
        </p>
        <p className="checkoutSeven11__instContent">2. Proceed to make payment with the amount on your invoice</p>
        <p className="checkoutSeven11__instTitle">STEP 3: TRANSACTION COMPLETED</p>
        <p className="checkoutSeven11__instContent">1. Receive your proof of payment from the cashier</p>
        <p className="checkoutSeven11__instContent">2. Your transaction is successful</p>
      </div>
      <div className="checkoutSeven11__code">
        <Barcode value={data?.payment_code} width={1.2} fontSize={12} />
        <p>
          Payment Code: <span>{data?.payment_code}</span>
        </p>
        {data.expired && (
          <p>
            Pay before <span>{getExpiredTime()}</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default CheckoutSeven11;
