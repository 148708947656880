import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import req from "../../utils/axios";
import getStatus from "../../utils/tradeStatusCode";
import { setTradeStatus } from "../../redux/actions";
import { isCreditCard } from "../../utils/validations";
import { useHistory } from "react-router-dom";

function AutoQuery() {
  const { tradeStatus, prepayId, method, payChannel } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const isAutoQuery =
    isCreditCard() ||
    method === "Wallet" ||
    method === "PIX" ||
    (method === "Crypto" && payChannel === "TripleA") ||
    tradeStatus.query;
  const getQueryStatus = () => {
    // console.log("getQueryStatus Called!");
    req
      .post(`/api/trade/query?prepay_id=${prepayId}`)
      .then((res) => {
        // console.log("query response");
        if (res?.data?.trade_no) {
          let tradeStatus = getStatus(res.data?.trade_status);
          dispatch(setTradeStatus(tradeStatus));
          // tradeStatus !== "process" && history.replace(`/result?prepay_id=${prepayId}${returnUrlAsParam()}`);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    // console.log("query useeffect");
    if (!["fail", "success", "other"].includes(tradeStatus.id) && isAutoQuery) {
      let increment = 0;
      const interval = setInterval(() => {
        increment++;
        // console.log("increment: ", increment);
        if ([10, 30, 60, 120, 360, 840].includes(increment)) {
          getQueryStatus();
          // console.log("queryed !");
        } else if (increment > 840 || increment < 0) {
          clearInterval(interval);
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isAutoQuery]);
  return <></>;
}

export default AutoQuery;
