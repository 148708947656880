import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useDispatch } from "react-redux";
import { setDesktopView } from "../../redux/actions";
import { useEffect } from "react";

function ViewChange() {
  const isDesktop = useMediaQuery("only screen and (min-width: 801px)");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDesktopView(isDesktop));
  }, [isDesktop]);

  console.log("ViewChange", isDesktop);

  return <></>;
}

export default React.memo(ViewChange);
