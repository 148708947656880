import React from "react";
import InfoLogo from "../../img/info_icon2.svg";
import "./Warning.scss";

function Warning({ children, bg = "#faf4ea", color = "#844700", icon = InfoLogo }) {
  return (
    <div>
      <div className="warning" style={{ backgroundColor: bg, color: color }}>
        <img src={icon} alt="" />
        <p>{children}</p>
      </div>
    </div>
  );
}

export default Warning;
