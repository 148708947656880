import React from "react";
import { useSelector } from "react-redux";

function CheckoutKashIo() {
  const { payData } = useSelector((state) => state);
  return (
    <div>
      <iframe src={payData.pay_url} frameborder="0" title="kashio-iframe" width="98%" height="800"></iframe>
    </div>
  );
}

export default CheckoutKashIo;
