import React from "react";
import Download from "../../img/icon_download.png";
import { useTranslation } from "react-i18next";
import "./SaveButton.scss";
var Scroll = require("react-scroll");
var scroller = Scroll.scroller;

function SaveButton({ link }) {
  const { t } = useTranslation();
  return (
    <div className="saveButton">
      <button
        className="saveButton__button"
        onClick={() => {
          window.open(link, "_blank");
          scroller.scrollTo("mobile-pdf-image", {
            duration: 500,
            smooth: true,
            offset: -100, // Scrolls to element + 50 pixels down the page
          });
        }}
      >
        <span>{t("save_pdf")}</span>
        <img src={Download} alt="" />
      </button>
    </div>
  );
}

export default SaveButton;
