import React from "react";
import ShareOnSocialMedia from "./ShareOnSocialMedia";
import SaveButton from "./SaveButton";
import "./BarcodeAndPDF.scss";
import CopyButton from "./CopyButton";
var Barcode = require("react-barcode");
var Scroll = require("react-scroll");
var Element = Scroll.Element;

function BarcodeAndPDF({ mobile, barcode, pdf, image, barcodeWidth = 1, copyButton, barcodeInnerHtml }) {
  return (
    <div className="barcodeAndPDF">
      {mobile && image ? (
        <Element name="mobile-pdf-image">
          <img className="barcodeAndPDF__content__image" src={image} alt="" />
        </Element>
      ) : (
        pdf && <embed src={pdf} type="application/pdf" width="50%" height="600" />
      )}
      <div className="barcodeAndPDF__barcode">
        <div style={{ overflow: "auto" }}>
          {barcode && <Barcode value={barcode} width={barcodeWidth} fontSize={11} />}
          {barcodeInnerHtml && (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              {!mobile && <div dangerouslySetInnerHTML={{ __html: barcodeInnerHtml }} />}
              <span
                style={{
                  textAlign: "center",
                  fontSize: "11px",
                  border: "1px solid #878787",
                  borderRadius: "4px",
                  padding: "5px",
                }}
              >
                {copyButton?.text}
              </span>
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
          {copyButton && <CopyButton text={copyButton?.text} buttonText={copyButton?.buttonText} />}
          <ShareOnSocialMedia text={barcode} />
          {pdf && <SaveButton link={pdf} />}
        </div>
      </div>
    </div>
  );
}

export default BarcodeAndPDF;
