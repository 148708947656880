import React, { useRef, useImperativeHandle, forwardRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import CvvInfo from "../../../img/cvv_info2.jpg";
import CardNoInfo from "../../../img/cardNo_info2.jpg";
import ExpDateInfo from "../../../img/expDate_info2.jpg";
import InfoPromp from "../../comp/InfoPromp";
import { useTranslation } from "react-i18next";
import Installment from "../Installment";
import { useSelector, useDispatch } from "react-redux";
import Name from "../Name";
import TaxId from "../TaxId";
import MobilePhone from "../MobilePhone";
import Email from "../Email";
import Address from "../Address/Address";
import { issuer } from "../../../utils/formats";
import { useEffect } from "react";
import { setAlert } from "../../../redux/actions";
import FieldsSkeleton from "../../Pages/Home/FieldsSkeleton";
import { queryKey } from "../../../utils/tokanization";

function CustomField(props) {
  const { inputRef, setFocusOnInput, name, ...other } = props;

  return (
    <div
      {...other}
      ref={inputRef}
      id={name}
      onFocus={() => {
        setFocusOnInput(true);
      }}
    />
  );
}

function CommonField({ setFocusOnInput, id, type }) {
  const { t } = useTranslation();
  const label = {
    cardno: t("card_no"),
    date: t("exp_date"),
    cvv: "CVV",
  };
  const prompImage = {
    cardno: CardNoInfo,
    date: ExpDateInfo,
    cvv: CvvInfo,
  };
  return (
    <TextField
      size="small"
      variant="outlined"
      label={label[type]}
      InputLabelProps={{
        shrink: true,
      }}
      // error={!props.paypalCreditCard?.expDate?.valid && props.paypalCreditCard?.expDate?.checked}
      //   helperText={
      //     !props.paypalCreditCard?.expDate?.valid && props.paypalCreditCard?.expDate?.checked && t("invalid_exp_date")
      //   }
      inputProps={{
        setFocusOnInput: setFocusOnInput,
        name: id,
      }}
      InputProps={{
        inputComponent: CustomField,
        endAdornment: (
          <InputAdornment position="end">
            <InfoPromp promp={<img src={prompImage[type]} alt="" />} />
          </InputAdornment>
        ),
      }}
      onFocus={() => {
        setFocusOnInput(true);
      }}
      onBlur={() => {
        // props.setPaypalCreditCard({ type: "keepPrev", key: "expDate", value: { checked: true } });
      }}
    />
  );
}

const MpCreditCard = forwardRef(
  (
    {
      form,
      setForm,
      setFocusOnInput = () => {},
      setIsloading = () => {},
      doPay = () => {},
      handleError = () => {},
      mpReadyState,
    },
    ref
  ) => {
    const [isMpReady, setIsMpReady] = mpReadyState;
    const { t } = useTranslation();
    const [tradeInfo, loadedLibs, paymentInfo, method, payChannel, prepayId] = useSelector((state) => [
      state.tradeInfo,
      state.loadedLibs,
      state.paymentInfo,
      state.method,
      state.payChannel,
      state.prepayId,
    ]);
    const dispatch = useDispatch();
    const submitRef = useRef(null);
    const [reqData, setReqData] = useState(null);
    const [mpData, setmpData] = useState(false);
    const [isFormMounting, setIsFormMounting] = useState(true);

    useEffect(() => {
      if (reqData && mpData) {
        doPay({ ...reqData, ...mpData });
      }
    }, [reqData, mpData]);

    const updateMpSelectOptions = (data) => {
      // console.log("data:  ", data);
      document.getElementById(
        "form-checkout__installments"
      ).innerHTML = `<option value="${data.installments}">${data.installments}</option>`;

      document.getElementById(
        "form-checkout__identificationType"
      ).innerHTML = `<option value="${data.id.type}">${data.id.type}</option>`;
    };

    useImperativeHandle(ref, () => ({
      getToken(data) {
        setReqData(data);
        updateMpSelectOptions(data);
        submitRef.current?.click();
      },
    }));
    useEffect(() => {
      if (loadedLibs["CreditCard-Mercadopago"]) {
        setIsFormMounting(true);
        queryKey(method, prepayId)
          .then((publicKey) => {
            const mp = new window.MercadoPago(publicKey);
            try {
              const cardForm = mp?.cardForm({
                amount: String(paymentInfo?.pay_amount),
                iframe: true,
                form: {
                  id: "form-checkout",
                  cardNumber: {
                    id: "form-checkout__cardNumber",
                    placeholder: t("card_no"),
                  },
                  expirationDate: {
                    id: "form-checkout__expirationDate",
                    placeholder: "MM/YY",
                  },
                  securityCode: {
                    id: "form-checkout__securityCode",
                    placeholder: "Security Code",
                  },
                  cardholderName: {
                    id: "form-checkout__cardholderName",
                    placeholder: "Cardholder",
                  },
                  issuer: {
                    id: "form-checkout__issuer",
                    placeholder: "Issuing bank",
                  },
                  installments: {
                    id: "form-checkout__installments",
                    placeholder: "Installments",
                  },
                  identificationType: {
                    id: "form-checkout__identificationType",
                    placeholder: "Document type",
                  },
                  identificationNumber: {
                    id: "form-checkout__identificationNumber",
                    placeholder: "Document number",
                  },
                  cardholderEmail: {
                    id: "form-checkout__cardholderEmail",
                    placeholder: "Email",
                  },
                },
                callbacks: {
                  onFormMounted: (error) => {
                    if (error) {
                      handleError(error);
                      return console.warn("Form Mounted handling error: ", error);
                    }
                    console.log("Form mounted");
                    setTimeout(() => {
                      setIsFormMounting(false);
                      setIsMpReady(true);
                    }, 5000);
                  },
                  onSubmit: (event) => {
                    // console.log("onsubmit called!!!!!!!!");
                    event.preventDefault();

                    const {
                      paymentMethodId: payment_method_id,
                      issuerId: issuer_id,
                      cardholderEmail: email,
                      amount,
                      token,
                      installments,
                      identificationNumber,
                      identificationType,
                    } = cardForm.getCardFormData();

                    // callback for success
                    let mpData = {
                      extra_token: JSON.stringify({ token, issuer_id, payment_method_id }),
                    };
                    setmpData(mpData);
                    // console.log("onSubmit >>>>>> cardFormData: ", cardForm.getCardFormData());
                  },
                  onFetching: (resource) => {
                    // console.log("Fetching resource: ", resource);
                  },
                  onError: (error) => {
                    console.log("Error in mp credit card!!!!!!!!!!!: ", error);
                    setIsloading(false);
                    dispatch(setAlert({ state: true, message: t("fill_all") }));
                  },
                },
              });
            } catch (e) {
              handleError(e);
            }
          })
          .catch((err) => {
            handleError(err);
          });
      }
    }, [loadedLibs["CreditCard-Mercadopago"]]);
    return (
      <div>
        {isFormMounting && method === "CreditCard" && payChannel === "Mercadopago" && <FieldsSkeleton />}
        <form
          id="form-checkout"
          style={{
            display: !isFormMounting && method === "CreditCard" && payChannel === "Mercadopago" ? "block" : "none",
          }}
        >
          <div className="home--row">
            <Name form={form} setForm={setForm} tradeInfo={tradeInfo} creditcard={true} />
            <CommonField type="cardno" setFocusOnInput={setFocusOnInput} id="form-checkout__cardNumber" />
          </div>
          <div className="home--row">
            <CommonField type="date" setFocusOnInput={setFocusOnInput} id="form-checkout__expirationDate" />
            <CommonField type="cvv" setFocusOnInput={setFocusOnInput} id="form-checkout__securityCode" />
          </div>
          <div className="home--row">
            <Installment form={form} setForm={setForm} />
            <TaxId form={form} setForm={setForm} tradeInfo={tradeInfo} />
          </div>
          <div className="home--row">
            <Email form={form} setForm={setForm} tradeInfo={tradeInfo} />
          </div>
          <Address form={form} setForm={setForm} />
          <div style={{ display: "none" }}>
            <input type="text" id="form-checkout__cardholderName" value={form.name.value} />
            <select id="form-checkout__issuer">
              <option value={issuer}>{issuer}</option>
            </select>
            <select id="form-checkout__installments">
              <option value={form.installments.stage}>{form.installments.stage}</option>
            </select>
            <select id="form-checkout__identificationType">
              <option value={form.idType}>{form.idType}</option>
            </select>
            <input
              type="text"
              id="form-checkout__identificationNumber"
              value={form.taxId.value.replace(/[-/.]/g, "")}
            />
            <input type="email" id="form-checkout__cardholderEmail" value={form.email.value} />
            <button ref={submitRef} type="submit" id="form-checkout__submit">
              Pay
            </button>
          </div>
        </form>
      </div>
    );
  }
);

export default MpCreditCard;
