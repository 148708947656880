export const gaTagData = {
  BRA: {
    method: {
      CreditCard: "1",
      Boleto: "2",
      DepositExpress: "3",
      Lottery: "4",
      Wallet: "5",
      PIX: "6",
    },
    channel: {
      PicPay: "12",
      Paypal: "13",
      AME: "14",
      Skrill: "15",
      Paysafecard: "16",
      Eprepag: "17",
      MOL: "18",
    },
  },
  MEX: {
    method: {
      SPEI: "28",
      OXXO: "29",
      Wallet: "30",
      CreditCard: "31",
      Cash: "32",
    },
    channel: {
      Todito: "33",
    },
  },
  CHL: {
    method: {
      Khipu: "43",
      KLAP: "44",
      Wallet: "45",
    },
    channel: {
      Mach: "46",
      Vita: "47",
    },
  },
  COL: {
    method: {
      PSE: "57",
      Efecty: "58",
      Wallet: "59",
    },
    channel: {
      TPaga: "60",
    },
  },
  PER: {
    method: {
      CreditCard: "70",
      BankTransfer: "71",
      Cash: "72",
      PagoEfectivo: "73",
    },
  },
  ECU: {
    method: {
      BankTransfer: "83",
      Cash: "84",
    },
  },
  CRI: {
    method: {
      BankTransfer: "94",
      Cash: "95",
    },
  },
  GTM: {
    method: {
      BankTransfer: "105",
      Cash: "106",
    },
  },
  PAN: {
    method: {
      BankTransfer: "116",
      Cash: "117",
    },
  },
  NIC: {
    method: {
      BankTransfer: "127",
      Cash: "128",
    },
  },
  SLV: {
    method: {
      BankTransfer: "138",
      Cash: "139",
    },
  },
  HKG: {
    method: {
      Wallet: "149",
    },
    channel: {
      AlipayHK: "159",
    },
  },
  KOR: {
    method: {
      Wallet: "160",
    },
    channel: {
      KakaoPayAlipay: "161",
    },
  },
  THA: {
    method: {
      Wallet: "171",
    },
    channel: {
      TrueMoney: "172",
      RabbitLinePay: "173",
    },
  },
  MYS: {
    method: {
      Wallet: "183",
    },
    channel: {
      TNG: "184",
    },
  },
  IDN: {
    method: {
      Wallet: "194",
    },
    channel: {
      DANA: "195",
    },
  },
  CHN: {
    method: {
      Wallet: "205",
    },
    channel: {
      AlipayCN: "206",
    },
  },
  PHL: {
    method: {
      CreditCard: "216",
      OTC: "217",
      DirectDebit: "218",
      Wallet: "219",
      GCash: "220",
    },
    channel: {
      GCash: "220",
      GcashXendit: "220",
      Paymaya: "221",
      BPI: "222",
      UBP: "223",
    },
  },
  RUS: {
    method: {
      AlfaClick: "233",
      Qiwi: "234",
      WebMoney: "235",
      YandexMoney: "236",
      Wallet: "237",
    },
    channel: {
      YooMoney: "238",
    },
  },
  SAU: {
    method: {
      CreditCard: "248",
      Wallet: "249",
    },
    channel: {
      MADA: "250",
    },
  },
  IND: {
    method: {
      UPI: "260",
      NetBanking: "261",
    },
  },
  ARE: {
    method: {
      CreditCard: "271",
    },
  },
  KWT: {
    method: {
      CreditCard: "281",
      Wallet: "282",
    },
    channel: {
      KNet: "283",
    },
  },
  QAT: {
    method: {
      CreditCard: "293",
    },
  },
  OMN: {
    method: {
      CreditCard: "303",
    },
  },
  KHM: {
    method: {
      PiPay: "313",
      PayGo: "314",
    },
  },
  SGP: {
    method: {
      Wallet: "324",
    },
    channel: {
      GrabPay: "325",
      PayNow: "326",
    },
  },
};
