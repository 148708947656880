import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import loadApi from "../../animations/load-api.json";
import cardFail from "../../animations/card-fail.json";
import fail from "../../animations/fail.json";
import loading from "../../animations/loading.json";
import success from "../../animations/success.json";

function LottiAnimation({ anime, width = 200, height = 200, className }) {
  const animes = {
    loadApi: loadApi,
    cardFail: cardFail,
    fail: fail,
    loading: loading,
    success: success,
  };
  // Loading animation
  const lottieAnime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: lottieAnime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animes[anime],
    });
    // More logic goes here
  }, []);
  ////////////////////
  return <div className={className || ""} style={{ height: height, width: width }} ref={lottieAnime}></div>;
}

export default LottiAnimation;
