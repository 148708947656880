import React from "react";
import { connect } from "react-redux";
import "./CheckoutPaynet.scss";
import { useTranslation } from "react-i18next";
import BarcodeAndPDF from "../../comp/BarcodeAndPDF";
import CheckoutTitle from "./CheckoutTitle";

function CheckoutPaynet({ pdf, image, mobile = false, ...props }) {
  const { t } = useTranslation();
  return (
    <div className="checkoutPaynet">
      <CheckoutTitle />
      <div className="checkoutPaynet__instContainer">
        <p>{t("checkout_inst3")}</p>
        <p>{t("checkout_inst4")}</p>
      </div>
      <BarcodeAndPDF
        mobile={mobile}
        barcode={props.payData?.barcode}
        pdf={pdf}
        image={image}
        barcodeWidth={1.5}
      />
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    prepayId: state.prepayId,
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaynet);
