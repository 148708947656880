import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import { states } from "../../utils/regionMx";
import { checkState } from "../../utils/validations";

function State({ form, setForm, tradeInfo, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");
  return (
    <>
      <Autocomplete
        className="homeMobile__filling__content__input"
        value={form.state.value}
        onChange={(event, newValue) => {
          setForm((prev) => {
            return {
              ...prev,
              state: { ...prev.state, value: newValue },
            };
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={states[tradeInfo?.app_info?.region]}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("state")}
            variant="outlined"
            error={!form.state.valid}
            helperText={!form.state.valid && t("invalid_state")}
            size="small"
            inputProps={{ ...params.inputProps, autocomplete: "new-password", "ga-data": "344" }}
          />
        )}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onBlur={() => {
          checkState({ form: form, setForm: setForm });
        }}
      />
    </>
  );
}

export default State;
