import React from "react";
import ShowHideBox from "../comp/ShowHideBox";
import "./Faq.scss";
import { connect } from "react-redux";
import { useMethodConfig } from "../../utils/configurations";
import { useTranslation } from "react-i18next";

function Faq(props) {
  const { t } = useTranslation();
  const methodConfig = useMethodConfig();
  return (
    <div className="faq">
      <p className="faq__title">{t("faq")}</p>
      {props.tradeInfo?.channel_info_list?.map((item) => {
        return methodConfig[item.method]?.faq?.map((item) => (
          <ShowHideBox className="faq__box" title={item.q} content={item.a} />
        ));
      })}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
