import React, { useRef } from "react";
import "./EllipsisScroll.scss";

function EllipsisScroll({ text, className }) {
  const ref = useRef(null);

  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);
    };

    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [ref]);

  const handleScroll = (e) => {
    const scrollLeft = Math.round(e.target.scrollLeft);
    const scrollWidth = e.target.scrollWidth;
    const offsetWidth = e.target.offsetWidth;
    if (scrollWidth - scrollLeft === offsetWidth) {
      setIsOverflow(false);
    } else {
      setIsOverflow(true);
    }
    // console.log("scrollLeft", e.target.scrollLeft);
    // console.log("scrollWidth", e.target.scrollWidth);
    // console.log("offsetWidth ", e.target.offsetWidth);
  };

  return (
    <div className="ellipsisScroll">
      <p ref={ref} className={className} onScroll={handleScroll}>
        {text}
      </p>
      {isOverflow && <span>&nbsp;...</span>}
    </div>
  );
}

export default EllipsisScroll;
