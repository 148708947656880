import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    // width: "100%",
    "& .MuiSkeleton-rect": {
      backgroundColor: "#ededed",
    },
  },
});

export default function FieldsSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className="home--row">
        <Skeleton animation="wave" height={35} variant="rect" />
        <Skeleton animation="wave" height={35} variant="rect" />
      </div>
      <div className="home--row">
        <Skeleton animation="wave" height={35} variant="rect" />
        <Skeleton animation="wave" height={35} variant="rect" />
      </div>
      <div className="home--row">
        <Skeleton animation="wave" height={35} variant="rect" />
        <Skeleton animation="wave" height={35} variant="rect" />
      </div>
    </div>
  );
}
