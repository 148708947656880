import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import country2codes from "../../../utils/regionCodes";
import { checkCountry } from "../../../utils/validations";
import { connect } from "react-redux";

const countryListConfig = {
  "Trustly-PPRO": {
    EUR: ["AT", "DE", "EE", "ES", "FI", "LT", "LV", "NL"],
    DKK: ["DK"],
    GBP: ["GB"],
    NOK: ["NO"],
    SEK: ["SE"],
  },
  "Paysafecard-PPRO": {
    EUR: [
      "AT",
      "AU",
      "BE",
      "BG",
      "CA",
      "CH",
      "CY",
      "CZ",
      "DE",
      "DK",
      "ES",
      "FI",
      "FR",
      "GB",
      "GE",
      "GI",
      "GR",
      "HR",
      "HU",
      "IE",
      "IT",
      "LI",
      "LT",
      "LU",
      "MX",
      "MT",
      "NL",
      "NZ",
      "NO",
      "PE",
      "PL",
      "PT",
      "RO",
      "SE",
      "SI",
      "SK",
      "UY",
    ],
  },
  "Payconiq-PPRO": {
    EUR: ["BE", "LU"],
  },
  "Multibanco-PPRO": {
    EUR: ["PT"],
  },
};

function Country({ form, setForm, setFocusOnInput = () => {}, ...props }) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");
  const [countryOptions, setCountryOptions] = useState(country2codes.map((item) => item.country_name));

  useEffect(() => {
    if (!form.country.checked && countryOptions?.includes?.(props.geoLocation?.country_name)) {
      setForm((prev) => {
        return {
          ...prev,
          country: { ...prev.country, value: props.geoLocation?.country_name ?? "" },
        };
      });
    }
  }, [props.geoLocation, countryOptions]);

  useEffect(() => {
    const countryConfig = countryListConfig[`${props.method}-${props.payChannel}`]?.[props.tradeInfo.pay_currency];
    if (countryConfig) {
      const newCountryList = country2codes.reduce((result, item) => {
        if (countryConfig?.includes?.(item.alpha2_code)) {
          return [...result, item.country_name];
        } else {
          return result;
        }
      }, []);
      setCountryOptions(newCountryList);
      setForm((prev) => {
        return {
          ...prev,
          country: newCountryList.includes(prev.country.value)
            ? { ...prev.country }
            : {
                ...prev.country,
                value: newCountryList.length === 1 ? newCountryList[0] : "",
                valid: true,
                checked: newCountryList.length === 1 ? true : false,
              },
        };
      });
    }
  }, [props.method, props.payChannel]);

  return (
    <>
      <Autocomplete
        className="homeMobile__filling__content__input"
        value={form.country.value}
        onChange={(event, newValue) => {
          setForm((prev) => {
            return {
              ...prev,
              country: { ...prev.country, value: newValue ?? "" },
            };
          });
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={countryOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("country")}
            variant="outlined"
            error={!form.country.valid}
            helperText={!form.country.valid && t("invalid_country")}
            size="small"
            inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
          />
        )}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onBlur={() => {
          checkCountry({ form: form, setForm: setForm });
        }}
      />
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    geoLocation: state.geoLocation,
    tradeInfo: state.tradeInfo,
    method: state.method,
    payChannel: state.payChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Country);
