import React, { useState, useEffect } from "react";
import "./TimeRemaining.scss";
import { useTranslation } from "react-i18next";
import TimeExpired from "../../img/time_expired.png";

function TimeRemaining({ time, countdown, image, disable = () => {} }) {
  const { t } = useTranslation();
  const [remainTime, setRemainTime] = useState({
    day: "00",
    hour: "00",
    min: "00",
    sec: "",
  });
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      let timeValue = time;
      let remain = countdown !== undefined ? 1000 * countdown-- : 5400000 - (Date.now() - timeValue);
      if (remain <= 0) {
        setRemainTime({ day: "00", hour: "00", min: "00", sec: "00" });
        setIsExpired(true);
        clearInterval(interval);
        disable();
      } else if (!isNaN(remain)) {
        //console.log(`time: ${time}, remain: ${remain}`);
        let timeString = new Date(remain).toISOString();
        let value = timeString.slice(11, 19);
        let day = timeString.slice(8, 10) - 1;
        day = String(day).length === 1 ? "0" + day : String(day);
        value = value.split(":");
        //console.log(value);
        setRemainTime({ day: day, hour: value[0], min: value[1], sec: value[2] });
      }
      //console.log("time called", interval);
    }, 1000);

    return () => {
      //console.log("component will unmount:", interval);
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="time">
      <img src={isExpired ? TimeExpired : image} alt="" />
      <div className="time__timeValueContainer">
        {isExpired ? (
          <p className="time__expireNotice">{t("order_timeout")}</p>
        ) : (
          <p>
            <span>{t("time_remain")}</span>
            <span className="time__timeValue">
              {remainTime.day !== "00" && `${remainTime.day}d:`}
              {!(remainTime.day === "00" && remainTime.hour === "00") && `${remainTime.hour}h:`}
              {!(remainTime.day === "00" && remainTime.hour === "00" && remainTime.min === "00") &&
                `${remainTime.min}m:`}
              {remainTime.sec && `${remainTime.sec}s`}

              {/* {`${remainTime.hour}h:${remainTime.min}m:${remainTime.sec}s`} */}
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

export default TimeRemaining;
