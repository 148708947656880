import "./CheckoutFawryCash.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CopyButton from "../../comp/CopyButton";
import FawryIcon from "../../../img/fawry.png";
import { FormatMoney } from "../../../utils/formats";

function CheckoutFawryCash() {
  const { payData } = useSelector((state) => state);
  const { t } = useTranslation();

  return (
    <div className="CheckoutFawryCash">
      <section style={{ padding: "5px 0" }}>
        <p className="content">{t("fawry.cash.content")}</p>
      </section>
      <section style={{ margin: "20px 0" }} className="table">
        <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #E7EBF0", fontSize: "15px" }}>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "4px 8px" }}
                >
                  <span>{t("payment_method")}</span>
                  <img src={FawryIcon} alt="" />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "4px 8px" }}
                >
                  <span>{t("amount")}</span>
                  <FormatMoney type="total" />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "4px 8px",
                    flexWrap: "wrap",
                  }}
                >
                  <span>{t("ref_no")}</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CopyButton text={payData.reference} onlyIcon minWidth={50} />
                    <span>{payData.reference} </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default CheckoutFawryCash;
