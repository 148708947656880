import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Correct from "../../img/correct.jpg";
import "./CreditCardExpTime.scss";
import { checkYear, checkMonth, checkCvc } from "../../utils/validations";
import CvvInfo from "../../img/cvv_info2.jpg";
import InfoPromp from "../comp/InfoPromp";

function CreditCardExpTime({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <div className="creditCardExpTime">
      <FormControl
        variant="outlined"
        size="small"
        error={!form.expMonth.valid}
        className="homeMobile__filling__content__input mui-select"
      >
        <InputLabel id="demo-simple-select-label">{t("exp_month")}</InputLabel>
        <Select
          value={form.expMonth.value}
          label={t("exp_month")}
          inputProps={{ "ga-data": "353" }}
          onChange={(event) => {
            setForm((prev) => {
              return {
                ...prev,
                expMonth: { ...prev.expMonth, value: event.target.value },
              };
            });
          }}
          onFocus={() => {
            setFocusOnInput(true);
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onBlur={() => {
            checkMonth({ form: form, setForm: setForm });
          }}
        >
          {Array(12)
            .fill()
            .map((item, index) => {
              return (
                <MenuItem value={String(index + 1).length > 1 ? index + 1 : `0${index + 1}`} key={index + 1}>
                  {String(index + 1).length > 1 ? index + 1 : `0${index + 1}`}
                </MenuItem>
              );
            })}
        </Select>
        {!form.expMonth.valid && <FormHelperText>{t("invalid_month")}</FormHelperText>}
      </FormControl>

      <FormControl
        variant="outlined"
        size="small"
        error={!form.expYear.valid}
        className="homeMobile__filling__content__input mui-select"
      >
        <InputLabel id="demo-simple-select-label">{t("exp_year")}</InputLabel>
        <Select
          value={form.expYear.value}
          label={t("exp_year")}
          inputProps={{ "ga-data": "352" }}
          onChange={(event) => {
            setForm((prev) => {
              return {
                ...prev,
                expYear: { ...prev.expYear, value: event.target.value },
              };
            });
          }}
          onFocus={() => {
            setFocusOnInput(true);
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onBlur={() => {
            checkYear({ form: form, setForm: setForm });
          }}
        >
          {Array(50)
            .fill()
            .map((item, index) => {
              return (
                <MenuItem value={String(year + index)} key={year + index}>
                  {year + index}
                </MenuItem>
              );
            })}
        </Select>
        {!form.expYear.valid && <FormHelperText>{t("invalid_year")}</FormHelperText>}
      </FormControl>

      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ autocomplete: "new-password", "ga-data": "354", inputMode: "numeric" }}
        error={!form.cvc.valid}
        helperText={!form.cvc.valid && t("invalid_cvv")}
        className="homeMobile__filling__content__input"
        label="CVV"
        type="password"
        variant="outlined"
        value={form.cvc.value}
        onChange={(event) => {
          let format = /[^\d]+/g;
          let value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.cvc.max) {
            value = value.slice(0, form.cvc.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              cvc: { ...prev.cvc, value: value },
            };
          });
        }}
        onBlur={() => {
          checkCvc({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                <img
                  src={Correct}
                  alt=""
                  style={{
                    display: form.cvc.valid && form.cvc.checked ? "block" : "none",
                    marginRight: "5px",
                  }}
                />
                <InfoPromp promp={<img src={CvvInfo} alt="" />} />
              </>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default CreditCardExpTime;
