import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../../img/correct.jpg";
import { checkVoucherNumber } from "../../../utils/validations";
import { formatVoucherNumber } from "../../../utils/formats";

function VoucherNumber({ form, setForm, setFocusOnInput = () => {} }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        inputProps={{ autocomplete: "new-password" }}
        error={!form.voucher_number.valid}
        helperText={!form.voucher_number.valid && "Invalid security PIN code"}
        className="homeMobile__filling__content__input"
        label="Security PIN code"
        variant="outlined"
        value={form.voucher_number.value}
        onChange={(event) => {
          var value = formatVoucherNumber(event.target.value);
          setForm((prev) => {
            return {
              ...prev,
              voucher_number: { ...prev.voucher_number, value: value },
            };
          });
        }}
        onBlur={() => {
          checkVoucherNumber({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.voucher_number.valid && form.voucher_number.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default VoucherNumber;
