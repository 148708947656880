import React, { useState } from "react";
import "./KushkiOtp.scss";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";

function KushkiOtp({ setIsLoading = () => {}, handleError, data, doPay = () => {} }) {
  const [otpValue, setOtpValue] = useState("");
  const { t } = useTranslation();

  const payByOtp = (otp) => {
    setIsLoading(true);
    window.kushkiInstance.requestSecureServiceValidation(
      {
        secureServiceId: data.secureId, // Replace with the received secureId
        otpValue: String(otp), // Replace with the OTP entered by the user
      },
      (response) => {
        if (response.code === "OTP000") {
          // Submit the code to your back-end
          doPay(data.reqData);
        } else {
          console.error("Error: ", response.error, "Code: ", response.code, "Message: ", response.message);
          handleError("incorrect code", "code_err");
        }
      }
    );
  };
  return (
    <div className="kushkiOtp">
      <div className="kushkiOtp__window">
        <div className="kushkiOtp__otpNotice">
          <p className="kushkiOtp__otpNotice__title">{t("kushki.otp.title")}</p>
          <p className="kushkiOtp__otpNotice__content">{t("kushki.otp.content")}</p>
          <div className="kushkiOtp__otpNotice__payment">
            <p>{t("kushki.otp.payment")}</p>
            <span>$X.XX</span>
          </div>
          <p className="kushkiOtp__otpNotice__note">{t("kushki.otp.notice")}</p>
        </div>
        <OtpInput
          containerStyle="kushkiOtp__container"
          inputStyle="kushkiOtp__input"
          value={otpValue}
          onChange={(otp) => {
            setOtpValue(otp);
            if (String(otp).length === 3) {
              payByOtp(otp);
            }
          }}
          numInputs={3}
          isInputNum={true}
          separator={<span>-</span>}
        />
      </div>
    </div>
  );
}

export default KushkiOtp;
