import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "./DropZoneCustom.scss";
import { useTranslation } from "react-i18next";
import Dropzone, { defaultClassNames } from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import FlipMove from "react-flip-move";

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
  return (
    <div className="dropZoneCustom">
      <div {...dropzoneProps}>{input}</div>
      {previews}
      {files.length > 0 && submitButton}
    </div>
  );
};

const Input =
  (image, title, notice) =>
  ({ accept, onFiles, files, getFilesFromEvent }) => {
    return (
      <label className="dropZoneCustom__label">
        <img src={image} alt="" />
        <div className="dropZoneCustom__label__text">
          <p>{title}</p>
          <p className="dropZoneCustom__label__textDragDrop">({notice})</p>
        </div>
        {files.length === 0 && (
          <input
            style={{ display: "none" }}
            type="file"
            accept={accept}
            multiple
            onChange={(e) => {
              getFilesFromEvent(e).then((chosenFiles) => {
                onFiles(chosenFiles);
              });
            }}
          />
        )}
      </label>
    );
  };

function DropZoneCustom({ image, title, form, setForm }) {
  const { t } = useTranslation();
  const [preview, setPreview] = useState(false);
  const handleChangeStatus = (fileWithMeta, status) => {
    //console.log(status, window.URL.createObjectURL(fileWithMeta.file));
    if (status === "done") {
      setForm((prev) => ({
        ...prev,
        file: { ...prev.file, value: fileWithMeta.file, checked: true, valid: true },
      }));
      setPreview(true);
    } else if (status === "removed") {
      setForm((prev) => ({
        ...prev,
        file: { ...prev.file, value: "", valid: false },
      }));
    }
  };
  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };
  return (
    <>
      <FlipMove enterAnimation="elevator" leaveAnimation="elevator" duration={400}>
        {preview && (
          <div className="dropZonePreview">
            <div className="dropZonePreview__container">
              <embed
                src={window.URL.createObjectURL(form.file.value)}
                type=""
                width={`${window.innerWidth * 0.8}`}
                height={`${window.innerHeight * 0.65}`}
              />
              <div className="dropZonePreview__button">
                <button
                  onClick={() => {
                    setPreview(false);
                  }}
                >
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
        )}
      </FlipMove>

      <Dropzone
        LayoutComponent={Layout}
        onChangeStatus={handleChangeStatus}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        multiple={false}
        maxFiles={1}
        InputComponent={Input(image, title, t("drag_notice"))}
        getFilesFromEvent={getFilesFromEvent}
        maxSizeBytes={1024 * 1024 * 5}
        accept=".pdf,.jpg,.png,.txt,.jpeg"
      />
    </>
  );
}

export default DropZoneCustom;
