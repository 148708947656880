import React, { useState, useEffect } from "react";
import "./Authorization.scss";
import OrderDetails from "../../comp/OrderDetails";
import TitleWithDetails from "../../comp/TitleWithDetails";
import { FormatMoney } from "../../../utils/formats";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { changeMethod, setTradeStatus, setPayData } from "../../../redux/actions";
import req from "../../../utils/axios";
import queryUrl from "../../../utils/queryUrl";
import { useHistory } from "react-router-dom";
import Ubp from "../../../img/ubp.png";
import Bpi from "../../../img/bpi.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OtpInput from "react-otp-input";
import Warning from "../../../img/warning.png";
import Close from "../../../img/close_gray.png";
import CountDown from "../../comp/CountDown";
import { toast } from "react-toastify";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import LottiAnimation from "../../comp/LottiAnimation";
var classNames = require("classnames");

const bankToImage = {
  BA_UBP: Ubp,
  BA_BPI: Bpi,
};

function Authorization(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const token = queryUrl("linked_account_token_id");
  const [isLoading, setIsLoading] = useState(true);
  const [accountInfo, setAccountInfo] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [otpWindow, setOtpWindow] = useState(false);
  const [warningWindow, setWarningWindow] = useState(false);
  const [countDownState, setCountDownState] = useState(false);
  const notify = (text) => {
    toast.warn(text, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleError = (err) => {
    props.setTradeStatus({ id: "fail", text: "Authorization failed" });
    history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
  };
  useEffect(() => {
    if (props.tradeInfo?.method === "DirectDebit" && token) {
      props.changeMethod("DirectDebit", "Xendit", undefined, handleError, () => {
        req
          .post(`/api/trade/query-linked-account-info?prepay_id=${props.prepayId}&token=${token}`)
          .then((res) => {
            if (res.data?.code === "10000") {
              setAccountInfo(res.data?.data);
              setIsLoading(false);
            } else {
              handleError("/query-linked-account-info endpoint error!");
            }
          })
          .catch(() => {
            handleError("Calling /query-linked-account-info endpoint error!");
          });
      });
    } else {
      handleError("Wrong payment method of missing parameter!");
    }
  }, []);

  const doPay = () => {
    if (selectedAccount) {
      setIsLoading(true);
      req
        .post(`/api/trade/pay?prepay_id=${props.prepayId}`, {
          method: "DirectDebit",
          amount: props.paymentInfo?.pay_amount,
          currency: props.paymentInfo?.pay_currency,
          out_trade_no: props.tradeInfo?.out_trade_no,
          prev_url: document.referrer,
          account_id: selectedAccount,
        })
        .then((res) => {
          props.setPayData(res.data?.data);
          if (res.data?.code === "10000") {
            if (accountInfo.channel_code === "BA_UBP") {
              props.setTradeStatus({ id: "success" });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            } else if (accountInfo.channel_code === "BA_BPI") {
              setOtpWindow(true);
              setCountDownState(true);
              setIsLoading(false);
            }
          } else {
            handleError("/pay endpoint error!");
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      setWarningWindow(true);
    }
  };

  const payByOtp = (otp) => {
    setIsLoading(true);
    req
      .post(`/api/trade/pay-by-otp?prepay_id=${props.prepayId}&otp_code=${otp}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data?.code === "10000") {
          if (res.data?.data?.partner_status?.toUpperCase() === "COMPLETED") {
            props.setTradeStatus({ id: "success" });
            history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          } else if (res.data?.data?.partner_status?.toUpperCase() === "PENDING") {
            props.setTradeStatus({ id: "process" });
            history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          } else {
            props.setTradeStatus({ id: "fail", text: "Authorization failed" });
            history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          }
        } else {
          notify("The SMS verification code is incorrect, please re-enter");
        }
      })
      .catch((err) => {
        notify("The SMS verification code is incorrect, please re-enter");
      });
  };

  const resentOtp = () => {
    req
      .post(`/api/trade/resend-otp?prepay_id=${props.prepayId}`)
      .then((res) => {
        if (res.data?.code === "10000") {
          props.setPayData(res.data?.data);
          setCountDownState(true);
        } else {
          // ignore
        }
      })
      .catch((err) => {
        // ignore
      });
  };

  return (
    <>
      <div className="puff__loader" style={{ display: isLoading ? "block" : "none" }}>
        <LottiAnimation anime="loadApi" height={100} className="loader" />
      </div>
      {warningWindow && (
        <div className="home__notice">
          <div className="home__notice__warning">
            <img
              className="home__notice__warningClose"
              src={Close}
              alt=""
              onClick={() => {
                setWarningWindow(false);
              }}
            />
            <img src={Warning} alt="" />
            <p className="home__notice__warningText">Please select an account.</p>
            <button
              className="home__notice__warningButton"
              onClick={() => {
                setWarningWindow(false);
              }}
            >
              Okay
            </button>
          </div>
        </div>
      )}
      {otpWindow && (
        <div className="authorization__otp">
          <div className="authorization__otpWindow">
            <div className="authorization__otpNotice">
              <p>MOBILE VERIFICATION</p>
              <p>
                Enter the 6-digit OTP we sent to your account registered number {props.payData?.otp_mobile_number} to
                continue with your purchase
              </p>
            </div>
            <OtpInput
              containerStyle="authorization__otp__container"
              inputStyle="authorization__otp__input"
              value={otpValue}
              onChange={(otp) => {
                setOtpValue(otp);
                if (String(otp).length === 6) {
                  payByOtp(otp);
                }
              }}
              numInputs={6}
              separator={<span>-</span>}
            />
            <div className="authorization__otpNoticeBottom">
              <p>
                Didn't recive OTP?{" "}
                <span
                  className={classNames({ "resent--active": !countDownState })}
                  onClick={() => {
                    !countDownState && resentOtp();
                  }}
                >
                  Resend
                </span>{" "}
                {countDownState && (
                  <span>
                    after{" "}
                    <CountDown
                      time={new Date(props.payData.otp_expiration_timestamp).getTime() - Date.now()}
                      timeIsUpCallback={() => {
                        setCountDownState(false);
                      }}
                    />
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="authorization">
        <div className="authorization__container">
          <div className="authorization__left">
            <div className="authorization__left__title">
              <img src={bankToImage[accountInfo.channel_code]} alt="" />
              <p>Select an Account to Pay</p>
            </div>
            <RadioGroup
              className="authorization__left__radioGroup"
              value={selectedAccount}
              onChange={(event) => {
                setSelectedAccount(event.target.value);
              }}
            >
              {accountInfo?.accounts?.map((item) => (
                <FormControlLabel
                  className="authorizationMobile__content__radioButton"
                  value={item.id}
                  control={<Radio />}
                  label={`${item.account_type}: ${item.account_details}`}
                />
              ))}
            </RadioGroup>
            <div className="authorization__left__confirmButton">
              <button onClick={doPay}>Confirm</button>
            </div>
          </div>
          <div className="authorization__right">
            <OrderDetails showDetails={true} backToShop={true} />
          </div>
        </div>
      </div>
      {/* ~~~~~~~~~~~~~~~~~~~~~ */}
      {/*   Mobile starts here  */}
      {/* ~~~~~~~~~~~~~~~~~~~~~ */}
      <div className="authorizationMobile">
        <div className="authorizationMobile__top">
          <TitleWithDetails />
          <hr />
          <div className="authorizationMobile--floatRight">
            <p>
              <span className="authorizationMobile__top__totalText">{t("total")}</span>
              <span className="authorizationMobile__top__totalValue">
                <FormatMoney type="total" />
              </span>
            </p>
          </div>
        </div>
        <div className="authorizationMobile__content">
          <div className="authorizationMobile__content__title">
            <img src={bankToImage[accountInfo.channel_code]} alt="" />
            <p>Select an Account to Pay</p>
          </div>
          <RadioGroup
            className="authorizationMobile__content__radioGroup"
            value={selectedAccount}
            onChange={(event) => {
              setSelectedAccount(event.target.value);
            }}
          >
            {accountInfo?.accounts?.map((item) => (
              <FormControlLabel
                className="authorizationMobile__content__radioButton"
                value={item.id}
                control={<Radio />}
                label={`${item.account_type}: ${item.account_details}`}
              />
            ))}
          </RadioGroup>
        </div>
        <div className="authorizationMobile__footer">
          <button className="authorizationMobile__footer__confirmButton" onClick={doPay}>
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    prepayId: state.prepayId,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeStatus: (item) => dispatch(setTradeStatus(item)),
    setPayData: (item) => dispatch(setPayData(item)),
    changeMethod: (method, channel, setIsLoading, handleError, callback) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError, callback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Authorization);
