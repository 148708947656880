import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import PaymentCode from "../../comp/PaymentCode";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "./CheckoutKlapCash.scss";
import Klap from "../../../img/klap.png";

function CheckoutKlapCash(props) {
  const { t } = useTranslation();
  return (
    <div className="checkoutKlapCash">
      <div className="checkoutKlapCash__title">
        <CheckoutTitle />
        <img src={Klap} alt="" />
      </div>
      <div className="checkoutKlapCash__mobileCopy">
        <hr />
        <PaymentCode value={props.payData.reference} copy />
        <hr />
      </div>
      <div className="checkoutKlapCash__inst">
        <p className="checkoutKlapCash__instTitle">{t("clap_cash_title")}</p>

        <ol className="checkoutKlapCash__instCont">
          <li>
            <span>{t("klap_store1")}</span>
            <a href="https://www.klap.cl/home-comercios#mapa-comercios" target="_blank" rel="noreferrer">
              {t("klap_store2")}
            </a>
            <span>{t("klap_store3")}</span>
          </li>
          <li>
            <span>{t("clap_cash_ins1")}</span>
            <span className="checkoutKlapCash__instCont--bold">{props.payData?.reference}</span>
          </li>
          <li>{t("clap_cash_ins2")}</li>
          <li>{t("clap_cash_ins3")}</li>
        </ol>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutKlapCash);
