import React from "react";
import "./Tooltip.scss";
var classNames = require("classnames");

function Tooltip({ children, tooltip, color, width, position = "centre", padding = 10 }) {
  const style = {
    width: width ? `${width}px` : "auto",
    backgroundColor: color || "#f1a00b",
    transform: position === "centre" ? "translate(-50%, 0)" : "none",
    padding: `${padding}px`,
    // marginLeft: position !== "centre" ? "0px" : width ? `-${width / 2}px` : "-150px",
  };

  const tooltipClass = classNames("tooltip__content", {
    "bottom-left": position === "bottom-left",
    "bottom-right": position === "bottom-right",
  });
  return (
    <div className="tooltip">
      {children}
      <div className={tooltipClass} style={style}>
        {tooltip}
      </div>
      <div
        className="tooltip__arrow"
        style={{ borderColor: `transparent transparent ${color || "#f1a00b"} transparent` }}
      ></div>
    </div>
  );
}

export default Tooltip;
