import React, { useEffect, useState } from "react";
import BarcodeAndPDF from "../../comp/BarcodeAndPDF";
import CheckoutTitle from "./CheckoutTitle";
import { connect } from "react-redux";
import "./CheckoutBarcode.scss";
import { useTranslation } from "react-i18next";
import PagoFacil from "../../../img/pago_facil.png";
import RapiPago from "../../../img/rapi_pago.png";
import Servipag from "../../../img/servipag.png";

const getLogo = {
  PagoFacil: PagoFacil,
  Rapipago: RapiPago,
  Servipag: Servipag,
};

function CheckoutBarcode(props) {
  const { t } = useTranslation();
  const [dueDate, setDueDate] = useState([]);
  useEffect(() => {
    setDueDate(new Date(props.payData.due_date).toLocaleString?.());
  }, []);
  return (
    <div className="checkoutBarcode">
      <div className="checkoutBarcode__title">
        <CheckoutTitle />
        <img src={getLogo[props.method]} alt="" />
      </div>
      <div className="checkoutBarcode__ins">
        <p className="checkoutBarcode__howTo">{t("wallet_inst3")}</p>
        <ol>
          {props.method === "Cash" && props.payChannel === "Localpayment" ? (
            <li>
              {t("barcode_step1.1", {
                key: props.payData.bank_name || t("payment_point"),
              })}{" "}
              <span className="checkoutBarcode__reference">{props.payData?.barcode}</span>
            </li>
          ) : (
            <li>
              {t("barcode_step1")} <span className="checkoutBarcode__reference">{props.payData?.reference}</span>
            </li>
          )}
          <li>{t("clap_cash_ins3")}</li>
          <li>
            {t("lp_ins2")} {dueDate}
          </li>
        </ol>
      </div>
      <div>
        <BarcodeAndPDF barcode={props.payData?.barcode} copyButton={{ text: props.payData?.barcode }} />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    method: state.method,
    payChannel: state.payChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBarcode);
