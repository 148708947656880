import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setPaymentInfo } from "../../redux/actions";
import { jsonParse } from "../../utils/jsonParse";
import { FormatMoney } from "../../utils/formats";

function Installment({ form, setForm, setFocusOnInput = () => {}, ...props }) {
  const { t } = useTranslation();
  const [installments, setInstallments] = useState([]);

  useEffect(() => {
    if (Array.isArray(props.paymentInfo?.fees?.installment_fees) && props.paymentInfo?.fees?.installment_fees?.length) {
      setInstallments(props.paymentInfo?.fees?.installment_fees);
      setForm((prev) => {
        return {
          ...prev,
          installments: props.paymentInfo?.fees?.installment_fees?.[0],
        };
      });
      props.setPaymentInfo({
        ...props.paymentInfo,
        pay_amount: props.paymentInfo?.fees?.installment_fees?.[0].amount,
        installment: props.paymentInfo?.fees?.installment_fees?.[0],
      });
    } else {
      setInstallments([
        {
          stage: 1,
          amount_each: props?.paymentInfo?.pay_amount,
          amount: props?.paymentInfo?.pay_amount,
          rate: 0,
        },
      ]);
      setForm((prev) => {
        return {
          ...prev,
          installments: {
            stage: 1,
            amount_each: props?.paymentInfo?.pay_amount,
            amount: props?.paymentInfo?.pay_amount,
            rate: 0,
          },
        };
      });
      props.setPaymentInfo({
        ...props.paymentInfo,
        installment: {
          stage: 1,
          amount_each: props?.paymentInfo?.pay_amount,
          amount: props?.paymentInfo?.pay_amount,
          rate: 0,
        },
      });
    }
  }, [props.method]);

  return (
    <>
      <FormControl variant="outlined" size="small" className="homeMobile__filling__content__input">
        <InputLabel id="installments">{t("installments")}</InputLabel>
        <Select
          value={JSON.stringify(form.installments)}
          label={t("installments")}
          inputProps={{ "ga-data": "342" }}
          onChange={(event) => {
            setForm((prev) => {
              return {
                ...prev,
                installments: jsonParse(event.target.value),
              };
            });
            props.setPaymentInfo({
              ...props.paymentInfo,
              pay_amount: jsonParse(event.target.value).amount,
              installment: jsonParse(event.target.value),
            });
          }}
          onFocus={() => {
            setFocusOnInput(true);
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {installments.map((item, index) => {
            return (
              <MenuItem value={JSON.stringify(item)} key={index}>
                <span>
                  {item?.stage + ` ${t("month")} x `}
                  <FormatMoney data={item?.amount_each} />
                  {" = "}
                  <FormatMoney data={item?.amount} />
                </span>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    paymentInfo: state.paymentInfo,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setPaymentInfo: (item) => dispatch(setPaymentInfo(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Installment);
