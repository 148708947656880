import React from "react";
import "./MainSkeleton.scss";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

function MainSkeleton() {
  return (
    <>
      <div className="mainSkeleton">
        <div className="mainSkeleton__left">
          <Skeleton variant="text" width={350} height={20} />
          <div className="mainSkeleton__left__methods">
            {Array(5)
              .fill()
              .map((item, index) => (
                <Box margin={2} key={index}>
                  <Skeleton variant="circle" width={60} height={60} />
                </Box>
              ))}
          </div>
          <Skeleton variant="text" width={250} height={20} />
          <div className="mainSkeleton__left__form">
            {Array(2)
              .fill()
              .map((item, index) => (
                <div className="mainSkeleton__left__form__row" key={index}>
                  {Array(2)
                    .fill()
                    .map((item, index) => (
                      <Box key={index} width="100%" marginBottom="20px">
                        <Skeleton variant="text" width="30%" height={20} />
                        <Skeleton variant="rect" width={"90%"} height={40} />
                      </Box>
                    ))}
                </div>
              ))}
          </div>
        </div>
        <div className="mainSkeleton__right">
          <Skeleton variant="rect" width={"100%"} height={"100%"} />
        </div>
      </div>
      <div className="mainSkeletonMobile">
        <Skeleton variant="text" width="100%" height={200} />
        <Box display="flex" alignItems="center" justifyContent="space-between" width="90%" margin="auto">
          <Skeleton variant="circle" width={60} height={60} />
          <Skeleton variant="text" width="calc(100% - 70px)" height={20} />
        </Box>

        {Array(4)
          .fill()
          .map((item, index) => (
            <Box key={index} width="90%" margin="20px auto">
              <Skeleton variant="text" width="30%" height={20} />
              <Skeleton variant="rect" width={"100%"} height={40} />
            </Box>
          ))}
      </div>
    </>
  );
}

export default MainSkeleton;
