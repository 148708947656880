import React, { useState } from "react";
import "./PaygoOtp.scss";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import req from "../../../utils/axios";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import { useHistory } from "react-router-dom";
import { setTradeStatus } from "../../../redux/actions";

function PaygoOtp({ phone, setIsLoading = () => {}, handleError, ...props }) {
  const [otpValue, setOtpValue] = useState("");
  let history = useHistory();

  const payByOtp = (otp) => {
    setIsLoading(true);
    req
      .post(`/api/trade/paygo-check?prepay_id=${props.prepayId}&verification_code=${otp}`)
      .then((res) => {
        if (res.data?.code === "10000" && res.data?.data?.trade_status === "SUCCESS") {
          //window.location.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          props.setTradeStatus({ id: "process", query: true });
          history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
        } else {
          handleError("incorrect code", "code_err");
        }
      })
      .catch((err) => {
        if (err && !err.response) {
          handleError(err, "network_err");
        } else {
          handleError("Calling /paygo-check endpoint error!");
        }
      });
  };
  return (
    <div className="paygoOtp">
      <div className="paygoOtp__window">
        <div className="paygoOtp__otpNotice">
          <p>MOBILE VERIFICATION</p>
          <p>Enter the 5-digit OTP we sent to your phone number {phone} to continue with your purchase</p>
        </div>
        <OtpInput
          containerStyle="paygoOtp__container"
          inputStyle="paygoOtp__input"
          value={otpValue}
          onChange={(otp) => {
            setOtpValue(otp);
            if (String(otp).length === 5) {
              payByOtp(otp);
            }
          }}
          numInputs={5}
          isInputNum={true}
          separator={<span>-</span>}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setTradeStatus: (item) => dispatch(setTradeStatus(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaygoOtp);
