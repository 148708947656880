import React, { useEffect, useState } from "react";
import "./CheckoutBoleto.scss";
import { connect, useSelector } from "react-redux";
import BarcodeAndPDF from "../../comp/BarcodeAndPDF";
import { useTranslation } from "react-i18next";
import req from "../../../utils/axios";

function CheckoutBoleto({ pdf, image, mobile = false, ...props }) {
  const [desktopView, prepayId] = useSelector((state) => [state.desktopView, state.prepayId]);
  const { t } = useTranslation();
  const [barcodeInnerHtml, setBarcodeInnerHtml] = useState("<div></div>");

  useEffect(() => {
    if (desktopView) {
      req.post(`/api/trade/barcode-img/?prepay_id=${prepayId}`).then((res) => {
        if (res.data?.code === "10000") {
          setBarcodeInnerHtml(res.data.data);
        }
      });
    }
  }, [desktopView]);
  return (
    <div className="checkoutBoleto">
      <div className="checkoutBoleto__header">
        <p className="checkoutBoleto__header__title">{t("boleto_inst1")}</p>
        <div className="checkoutBoleto__header__contentContainer">
          <p className="checkoutBoleto__header__content">{t("boleto_inst2")}</p>
          <p className="checkoutBoleto__header__content">{t("boleto_inst3")}</p>
        </div>
      </div>
      <BarcodeAndPDF
        mobile={mobile}
        barcodeInnerHtml={barcodeInnerHtml}
        pdf={pdf}
        image={image}
        copyButton={{ text: props.payData?.bank_code }}
      />
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBoleto);
