import React, { useState, useEffect } from "react";
import "./PaymentMethodMobile.scss";
import Channel from "./Channel";
import { connect } from "react-redux";
import { changeMethod, setWalletChannels, setPaymentInfo } from "../../../redux/actions";
import Radio from "@material-ui/core/Radio";
import RightArrowBlack from "../../../img/right_arrow_black.png";
import { channelConfig } from "../../../utils/configurations";
import ChannelSelect from "./Peru/ChannelSelect";
import { useTranslation } from "react-i18next";
import Name from "../../form-input/Name";
import TaxId from "../../form-input/TaxId";
import Email from "../../form-input/Email";
import CreditCardExpTime from "../../form-input/CreditCardExpTime";
import CreditCardNumber from "../../form-input/CreditCardNumber";
import State from "../../form-input/State";
import City from "../../form-input/City";
import PostalCode from "../../form-input/PostalCode";
import StreetAndNumber from "../../form-input/StreetAndNumber";
import HiddenForm from "../../form-input/Openpay-creditcard/HiddenForm";
import CardNo from "../../form-input/todito/CardNo";
import PinNo from "../../form-input/todito/PinNo";
import Installment from "../../form-input/Installment";
import PaypalCreditcard from "../../form-input/Paypal-creditcard/PaypalCreditcard";
import Source from "../../form-input/Source";
import Cep from "../../form-input/Boleto/Cep";
import FirstName from "../../form-input/FirstName";
import LastName from "../../form-input/LastName";
import SubmitForm from "../../form-input/SubmitPay/SubmitForm";
import PaygoOtp from "../../form-input/Paygo/PaygoOtp";
import Checkbox from "@material-ui/core/Checkbox";
import FlipMove from "react-flip-move";
import { gaTagData } from "../../../utils/google-analytics/gaTagData";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CrediMax from "../../form-input/CrediMax/CrediMax";
import VisaCard from "../../../img/visa_card.png";
import MasterCard from "../../../img/master_card.png";
import Amex from "../../../img/amex.png";
import Address from "../../form-input/Address/Address";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountNumber from "../../form-input/Localpayment/AccountNumber";
import MobilePhone from "../../form-input/MobilePhone";
import BankList from "../../form-input/Localpayment/BankList";
import VoucherNumber from "../../form-input/Neosurf/VoucherNumber";
import MailImg from "../../../img/mail.png";
import PhoneImg from "../../../img/phone-icon.png";
import LocationImg from "../../../img/location-icon.png";
import PCI from "../../../img/pci_dss.png";
import AddressBox from "../../form-input/AddressBox";
import Warning from "../../comp/Warning";
import MpCreditCard from "../../form-input/Mp-creditcard/MpCreditCard";
import Country from "../../form-input/EU/Country";
import KushkiOtp from "../../form-input/Kushki/KushkiOtp";
import BankListPopUp from "../../form-input/OpenFinance/BankListPopUp";
var classNames = require("classnames");
var Scroll = require("react-scroll");
var Element = Scroll.Element;
var scroller = Scroll.scroller;

function PaymentMethod({
  image,
  imageMobile,
  text,
  type,
  channel,
  channelNotice,
  loading = () => {},
  form,
  setForm,
  setFocusOnInput,
  paypalChildRefMobile,
  submitData,
  paygoOtp,
  kushkiOtp,
  setShowPolicy,
  crediMaxChildRef,
  payRequest,
  isReadyToPay,
  handleError,
  doPay,
  mpChildRef,
  mpReadyState,
  isFillingRequired,
  ...props
}) {
  const [isMpReady, setIsMpReady] = mpReadyState;
  const { t, i18n } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const isPeru = () => {
    return type === "Cash" && channel === "SafetyPay" && props.tradeInfo?.app_info?.region === "PER";
  };
  useEffect(() => {
    //console.log("method changed");
    if (props.method === type) {
      setTimeout(() => {
        setIsActive(true);
      }, 50); // it is for the transition between paypal creditcard
    } else {
      setIsActive(false);
    }
  }, [props.method]);

  const gaData = gaTagData[props.tradeInfo?.app_info?.region]?.method?.[type] || "";

  const handleChange = () => {
    if (type === "Wallet") {
      props.setPaymentInfo("");
      props.setWalletChannels(type, handleError, loading);
    } else {
      props.changeMethod(type, channel, loading, handleError);
    }
  };

  return (
    <>
      <Element name={type}>
        <div className="methodMobile" id={type}>
          <div
            className="methodMobile__methodTitle"
            onClick={() => {
              if (props.method !== type) {
                handleChange();
              } else {
                setIsActive((prev) => !prev);
              }
            }}
            ga-data={gaData}
          >
            <FormControlLabel
              value="other"
              control={
                <Radio
                  disabled
                  checked={props.method === type}
                  //value={type}
                  ga-data={gaData}
                  inputProps={{ "ga-data": gaData }}
                />
              }
              label={text}
            />
            <div className="methodMobile__methodTitle__arrowLogo" ga-data={gaData}>
              <img className="methodMobile__methodTitle__logo" src={imageMobile} alt="" ga-data={gaData} />
              {/* {channelConfig(type, channel) && (
                <img
                  className={classNames("methodMobile__methodTitle__arrow", {
                    arrow__0: !isActive,
                    arrow__90: isActive,
                  })}
                  src={RightArrowBlack}
                  alt=""
                  ga-data={gaData}
                />
              )} */}
            </div>
            {props.promotions?.[type] && (
              <img
                className="methodMobile__methodTitle__promotion"
                src={
                  props.promotions?.[type]?.discountNotice[i18n.language] || props.promotions?.[type]?.discountNotice.en
                }
                alt=""
              />
            )}
          </div>

          {type === "CreditCard" && props.payChannel === "CrediMax" && !props.loadedLibs["CreditCard-CrediMax"] && (
            <div style={{ textAlign: "center", paddingBottom: "15px" }}>
              <CircularProgress size={24} style={{ color: "var(--luxtak--color)" }} />
            </div>
          )}
          {props.loadedLibs["CreditCard-CrediMax"] &&
            type === "CreditCard" &&
            channel === "CrediMax" &&
            window.innerWidth < 800 && (
              <>
                {type === "CreditCard" &&
                  channel === "CrediMax" &&
                  props.method === "CreditCard" &&
                  props.payChannel === "CrediMax" &&
                  isActive && <p className="methodMobile__filling__title">{t("filling_details")}</p>}

                <CrediMax
                  style={{
                    display:
                      type === "CreditCard" &&
                      channel === "CrediMax" &&
                      props.method === "CreditCard" &&
                      props.payChannel === "CrediMax" &&
                      isActive
                        ? "block"
                        : "none",
                    marginTop: "20px",
                  }}
                  ref={crediMaxChildRef}
                  payRequest={payRequest}
                  setIsLoading={loading}
                  form={form}
                  setForm={setForm}
                  isReadyToPay={isReadyToPay}
                  handleError={handleError}
                />
              </>
            )}

          {type === "CreditCard" && channel === "Mercadopago" && (
            <>
              {window.innerWidth < 800 && (
                <>
                  {isActive && <p className="methodMobile__filling__title">{t("filling_details")}</p>}
                  <MpCreditCard
                    form={form}
                    setForm={setForm}
                    setIsloading={loading}
                    doPay={doPay}
                    handleError={handleError}
                    ref={mpChildRef}
                    mpReadyState={mpReadyState}
                  />
                </>
              )}
            </>
          )}

          <FlipMove
            enterAnimation="accordionVertical"
            leaveAnimation="none"
            duration={400}
            delay={200}
            onStart={() => {
              scroller.scrollTo(type, {
                duration: 500,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
            }}
          >
            {props.method === type && isActive && (
              <div className="methodMobile__content">
                {channelConfig(type, channel) && (
                  <div className="methodMobile__channel__content">
                    {isPeru() ? (
                      <>
                        <ChannelSelect
                          channelList={props.channelList}
                          method={props.method}
                          loading={loading}
                          handleError={handleError}
                          v
                        />
                      </>
                    ) : (
                      Array.isArray(props.channelList[props.method]) &&
                      props.channelList[props.method]?.map((item) => {
                        return <Channel data={item} key={item.key} loading={loading} handleError={handleError} />;
                      })
                    )}
                  </div>
                )}
                {/* promotions and notices */}
                {channelNotice && props.method === type && (
                  <p className="methodMobile__channelNotice">{channelNotice}</p>
                )}
                {props.method === "Cash" &&
                  props.payChannel === "PayCash" &&
                  props.tradeInfo?.app_info?.region === "ECU" && (
                    <p className="methodMobile__channelPromotion__notice">
                      {t("pay_points1")}
                      <a
                        href="https://checkout.luxtak.com/public/luxtak-files/puntos-Ecuador.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("pay_points2")}
                      </a>
                      {t("pay_points3")}
                    </p>
                  )}
                <Element name="filling_detail">
                  <div className="methodMobile__content__filling">
                    {!["CreditCard-CrediMax", "CreditCard-Mercadopago"].includes(`${type}-${channel}`) &&
                      isFillingRequired && <p className="methodMobile__filling__title">{t("filling_details")}</p>}
                    <div className="methodMobile__content__filling__content">
                      {((type === "CreditCard" && props.payChannel === "CardPay") ||
                        (type === "CreditCard" && props.payChannel === "Movii") ||
                        (type === "CreditCard" && props.payChannel === "Izipay") ||
                        (type === "CreditCard" && props.payChannel === "IzipaySmileone") ||
                        (type === "CreditCard" && props.payChannel === "Multicaja") ||
                        (type === "CreditCard" && props.payChannel === "Adiq") ||
                        (type === "CreditCard" && props.payChannel === "Bamboo")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            creditcard={true}
                          />

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "Kushki" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          {kushkiOtp && (
                            <KushkiOtp
                              setIsLoading={loading}
                              handleError={handleError}
                              data={kushkiOtp}
                              doPay={doPay}
                            />
                          )}
                        </>
                      )}

                      {((type === "CreditCard" && props.payChannel === "Localpayment") ||
                        (type === "DebitCard" && props.payChannel === "Localpayment")) && (
                        <>
                          <div style={{ marginBottom: "15px", display: "flex", alignItems: "center" }}>
                            <img src={VisaCard} alt="" />
                            <img src={MasterCard} alt="" />
                            {type === "CreditCard" && props.payChannel === "Localpayment" && <img src={Amex} alt="" />}
                          </div>

                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "OpenPay" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {/* <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} /> */}

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {props?.tradeInfo?.app_info?.region !== "PER" && (
                            <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          )}
                          <HiddenForm form={form} />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                        <>
                          <div style={{ marginBottom: "15px" }}>
                            <img src={VisaCard} alt="" />
                            <img src={MasterCard} alt="" />
                          </div>
                          <Warning>
                            Please input your first and last name exactly as it appears on your credit/debit card,
                            otherwise the transaction may fail.
                          </Warning>
                          <FirstName
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            credircard
                            placeholder={t("fname_ph")}
                          />
                          <LastName
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            credircard
                            placeholder={t("lname_ph")}
                          />
                          {/* <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            creditcard={true}
                            setFocusOnInput={setFocusOnInput}
                          /> */}
                          {/* <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} /> */}

                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "CrediMax" && window.innerWidth < 800 && (
                        <>
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {(type === "CreditCard" || type === "RecurringCreditCard" || type === "PlayersBankCard") &&
                        props.payChannel === "Paypal" &&
                        props.paypalCreditCard.authorizationToken && (
                          <>
                            <PaypalCreditcard
                              authorizationToken={props.paypalCreditCard.authorizationToken}
                              loading={loading}
                              handleError={handleError}
                              ref={paypalChildRefMobile}
                              target="mobile"
                              setFocusOnInput={setFocusOnInput}
                            />
                            {props?.tradeInfo?.app_info?.region === "BRA" && (
                              <>
                                {(type === "CreditCard" || type === "PlayersBankCard") && (
                                  <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                                )}
                                <TaxId
                                  form={form}
                                  setForm={setForm}
                                  tradeInfo={props.tradeInfo}
                                  setFocusOnInput={setFocusOnInput}
                                />
                              </>
                            )}

                            <Email
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <MobilePhone
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />

                            <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          </>
                        )}

                      {type === "CreditCard" && props?.tradeInfo?.app_info?.region === "PHL" && (
                        <>
                          <CreditCardNumber
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <CreditCardExpTime form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <Installment form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {(type === "Wallet" || type === "ToditoCash") && props.payChannel === "Todito" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <p className="home--fillTitle">{t("todito_notice")}</p>

                          <CardNo form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <PinNo form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <State
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />
                          <City
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />

                          <PostalCode
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            tradeInfo={props.tradeInfo}
                          />
                          <StreetAndNumber form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {((type === "BankTransfer" && props.payChannel === "SafetyPay") ||
                        (type === "Khipu" && props.payChannel === "Khipu") ||
                        (type === "Efecty" && props.payChannel === "Payty") ||
                        (type === "Efecty" && props.payChannel === "PaytyV2") ||
                        (type === "Wallet" &&
                          (props.payChannel === "PicPay" ||
                            (props.payChannel === "Paypal" && props.tradeInfo?.app_info?.region === "BRA") ||
                            props.payChannel === "AME" ||
                            props.payChannel === "MOL" ||
                            props.payChannel === "TPaga" ||
                            props.payChannel === "Chek" ||
                            props.payChannel === "Mach" ||
                            props.payChannel === "ClaroPay" ||
                            props.payChannel === "Mercadopago" ||
                            props.payChannel === "Vita")) ||
                        (type === "DepositExpress" && props.payChannel === "Levpay") ||
                        ((type === "PagoEfectivo" || type === "BankTransfer" || type === "Cash") &&
                          props.payChannel === "PagoEfectivo") ||
                        (type === "KLAP" && props.payChannel === "Multicaja") ||
                        (type === "Cash" && props.payChannel === "Multicaja") ||
                        (type === "BankTransfer" && props.payChannel === "Multicaja") ||
                        (type === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
                        (type === "CreditCard" && props.payChannel === "Payku") ||
                        ((type === "Pago46" || type === "WebPay") && props.payChannel === "Payku") ||
                        (type === "BankTransfer" && props.payChannel === "ETpay") ||
                        (type === "SuRed" && props.payChannel === "PayValida") ||
                        (type === "GANA" && props.payChannel === "PayValida") ||
                        (type === "Bancolombia" && props.payChannel === "PayValida") ||
                        (type === "PlayersBankPIX" && props.payChannel === "BS2") ||
                        ((type === "PagoFacil" || type === "Servipag" || type === "Rapipago") &&
                          props.payChannel === "Localpayment") ||
                        (type === "SPEI" &&
                          (props.payChannel === "OpenPay" ||
                            props.payChannel === "STP" ||
                            props.payChannel === "Banwire")) ||
                        (type === "Lottery" && (props.payChannel === "Caixa" || props.payChannel === "Mercadopago")) ||
                        (type === "Rapipago" && props.payChannel === "Rapipago")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            mobile
                          />
                        </>
                      )}
                      {type === "PSE" && (props.payChannel === "Payty" || props.payChannel === "PaytyV2") && (
                        <TaxId
                          form={form}
                          setForm={setForm}
                          tradeInfo={props.tradeInfo}
                          setFocusOnInput={setFocusOnInput}
                        />
                      )}
                      {type === "Wallet" &&
                        (props.payChannel === "Daviplata" ||
                          props.payChannel === "Rappipay" ||
                          props.payChannel === "Dale" ||
                          props.payChannel === "Movii" ||
                          props.payChannel === "Nequi") && (
                          <>
                            <Name
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <TaxId
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />

                            <Email
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <MobilePhone
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />

                            <AddressBox form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          </>
                        )}

                      {type === "PSE" && props.payChannel === "ACH" && (
                        <>
                          <BankList form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <AddressBox form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {type === "Wallet" && props.payChannel === "QIWI" && (
                        <>
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {type === "PIX" &&
                        (props.payChannel === "BS2" ||
                          props.payChannel === "Bradesco" ||
                          props.payChannel === "Rendimento" ||
                          props.payChannel === "Nupay" ||
                          props.payChannel === "Genial" ||
                          props.payChannel === "StarkBank" ||
                          props.payChannel === "CHANNEL_GROUP_ROUTER" ||
                          props.payChannel === "Mercadopago" ||
                          props.payChannel === "StarkBankTransferSmile" ||
                          props.payChannel === "BTG" ||
                          props.payChannel === "Fastcash" ||
                          props.payChannel === "Pagsmile" ||
                          props.payChannel === "Delbank") && (
                          <>
                            <TaxId
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                              mobile
                            />
                            {form.name.show && (
                              <Name
                                form={form}
                                setForm={setForm}
                                tradeInfo={props.tradeInfo}
                                setFocusOnInput={setFocusOnInput}
                                mobile
                              />
                            )}
                            {form.email.show && (
                              <Email
                                form={form}
                                setForm={setForm}
                                tradeInfo={props.tradeInfo}
                                setFocusOnInput={setFocusOnInput}
                                mobile
                              />
                            )}
                          </>
                        )}
                      {((type === "Wallet" &&
                        (props.payChannel === "Paysafecard" ||
                          props.payChannel === "Skrill" ||
                          props.payChannel === "Eprepag" ||
                          props.payChannel === "PaypalXCG" ||
                          props.payChannel === "Papara")) ||
                        (type === "Crypto" && props.payChannel === "TripleA") ||
                        (type === "PSE" && props.payChannel === "PayValida")) && (
                        <Email
                          form={form}
                          setForm={setForm}
                          tradeInfo={props.tradeInfo}
                          setFocusOnInput={setFocusOnInput}
                          mobile
                        />
                      )}
                      {(((type === "Boleto" || type === "BoletoRapido") && props.payChannel === "BS2") ||
                        (type === "Boleto" && props.payChannel === "StarkBank") ||
                        (type === "Boleto" && props.payChannel === "Mercadopago") ||
                        (type === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Cep form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}
                      {((type === "Wallet" &&
                        (props.payChannel === "AlipayHK" ||
                          props.payChannel === "GCash" ||
                          props.payChannel === "KakaoPayAlipay" ||
                          props.payChannel === "KakaoPay" ||
                          props.payChannel === "TOSS" ||
                          props.payChannel === "PAYCO" ||
                          props.payChannel === "SamsungPay" ||
                          props.payChannel === "DANA" ||
                          props.payChannel === "TNG" ||
                          props.payChannel === "BOOST" ||
                          props.payChannel === "RabbitLinePay" ||
                          props.payChannel === "BPI" ||
                          props.payChannel === "TrueMoney" ||
                          props.payChannel === "GcashXendit" ||
                          props.payChannel === "Paymaya" ||
                          props.payChannel === "PayNow" ||
                          props.payChannel === "GrabPay" ||
                          props.payChannel === "OVO" ||
                          props.payChannel === "MADA" ||
                          props.payChannel === "KNet" ||
                          props.payChannel === "YooMoney" ||
                          props.payChannel === "Fawry" ||
                          props.payChannel === "AstroPay" ||
                          props.payChannel === "Fpay" ||
                          props.payChannel === "NaverPay" ||
                          props.payChannel === "FawryPay")) ||
                        ((type === "GCash" || type === "CreditCard" || type === "NetBanking" || type === "UPI") &&
                          props.payChannel === "SHAREitPay") ||
                        ((type === "AlfaClick" ||
                          type === "Qiwi" ||
                          type === "WebMoney" ||
                          type === "YandexMoney" ||
                          type === "UnionPay") &&
                          props.payChannel === "Payssion") ||
                        (type === "PiPay" && props.payChannel === "PiPay") ||
                        (type === "AlipayPlus" && props.payChannel === "AlipayPlus") ||
                        ((type === "CreditCard" || type === "BankTransfer" || type === "VirtualAccount") &&
                          props.payChannel === "Payletter") ||
                        (type === "OXXO" &&
                          (props.payChannel === "Banwire" ||
                            props.payChannel === "CardPay" ||
                            props.payChannel === "Bamboo" ||
                            props.payChannel === "Localpayment")) ||
                        (type === "OXXOPay" && props.payChannel === "Conekta") ||
                        (type === "CoDi" && props.payChannel === "STP") ||
                        (type === "Cash" && props.payChannel === "PayCash") ||
                        (type === "Cash" && props.payChannel === "SafetyPay") ||
                        (type === "SberBank" && props.payChannel === "YooMoney") ||
                        (type === "Transfiya" && props.payChannel === "Movii") ||
                        (type === "BNCR" && props.payChannel === "PayCash") ||
                        (type === "Cash" && props.payChannel === "FawryPay") ||
                        ((type === "BCR" || type === "BNCR") && props.payChannel === "PayValida")) && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {props.payChannel === "Fpay" && (
                            <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          )}
                        </>
                      )}

                      {type === "CreditCard" && props.payChannel === "QIWI" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {type === "PayGo" && props.payChannel === "EDEE" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          {paygoOtp && (
                            <PaygoOtp phone={form.phone.value} setIsLoading={loading} handleError={handleError} />
                          )}
                        </>
                      )}
                      {((type === "DirectDebit" && props.payChannel === "Xendit") ||
                        (type === "OTC" && props.payChannel === "Xendit") ||
                        (type === "Giropay" && props.payChannel === "PPRO")) && (
                        <>
                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <MobilePhone form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {(((type === "BankTransfer" || type === "Cash" || type === "CreditCard") &&
                        props.payChannel === "Monnet") ||
                        ((type === "BankTransfer" || type === "Cash") && props.payChannel === "KASHIO") ||
                        (type === "Wallet" && props.payChannel === "Tupay")) && (
                        <>
                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <MobilePhone form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}
                      {type === "BankTransfer" && props.payChannel === "Localpayment" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <BankList
                            form={form}
                            setForm={setForm}
                            setFocusOnInput={setFocusOnInput}
                            region={props.tradeInfo?.app_info?.region}
                          />
                          <AccountNumber
                            form={form}
                            setForm={setForm}
                            region={props.tradeInfo?.app_info?.region}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {(type === "BankTransfer" || type === "OTC") && props.payChannel === "HappyPay" && (
                        <BankList
                          form={form}
                          setForm={setForm}
                          tradeInfo={props.tradeInfo}
                          region={props.tradeInfo?.app_info?.region}
                          type={type === "OTC" ? "store" : "bank"}
                        />
                      )}

                      {type === "Cash" && props.payChannel === "Localpayment" && (
                        <>
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <BankList form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <MobilePhone
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {((type === "OpenFinance" && props.payChannel === "Mercadopago") ||
                        (type === "OpenFinance" && props.payChannel === "Iniciador")) && (
                        <>
                          {/* <BankList
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                            type="popup"
                          /> */}
                          <BankListPopUp form={form} setForm={setForm} />
                          <Name
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />

                          <TaxId
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                          <Email
                            form={form}
                            setForm={setForm}
                            tradeInfo={props.tradeInfo}
                            setFocusOnInput={setFocusOnInput}
                          />
                        </>
                      )}

                      {type === "UPI" && props.payChannel === "Tazapay" && (
                        <>
                          <FirstName form={form} setForm={setForm} />
                          <LastName form={form} setForm={setForm} />
                          <Email form={form} setForm={setForm} />
                        </>
                      )}

                      {(type === "Sofort" ||
                        type === "Neosurf" ||
                        type === "Bancontact" ||
                        type === "EPS" ||
                        type === "Giropay" ||
                        type === "iDeal" ||
                        type === "MyBank" ||
                        type === "P24" ||
                        type === "Trustly") &&
                        props.payChannel === "Emerchantpay" && (
                          <>
                            {type === "Neosurf" && (
                              <VoucherNumber form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                            )}
                            <Warning>Please type in your real name, otherwise the transaction will fail.</Warning>
                            <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                            <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                            <Email
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <MobilePhone
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <Address form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          </>
                        )}

                      {type === "Trustly" && props.payChannel === "PPRO" && (
                        <>
                          <FirstName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <LastName form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Email form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          <Country form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                        </>
                      )}

                      {(type === "Paysafecard" || type === "Payconiq" || type === "Multibanco") &&
                        props.payChannel === "PPRO" && (
                          <>
                            <Name
                              form={form}
                              setForm={setForm}
                              tradeInfo={props.tradeInfo}
                              setFocusOnInput={setFocusOnInput}
                            />
                            <Country form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} />
                          </>
                        )}

                      {props.tradeInfo?.is_original_source_required && props.method && props.payChannel && (
                        <Source form={form} setForm={setForm} setFocusOnInput={setFocusOnInput} mobile />
                      )}
                      {submitData && <SubmitForm target="mobile" data={submitData} />}
                    </div>
                    <div className="methodMobile__content___bottom">
                      <div className="methodMobile__content___checkboxContainer">
                        <Checkbox
                          size="small"
                          id="policy-checkbox"
                          checked={form.isPolicyAccepted}
                          onChange={(event) => {
                            setForm((prev) => ({ ...prev, isPolicyAccepted: event.target.checked }));
                          }}
                        />
                        <span className="methodMobile__content___privacyText1">
                          {t("privacy_policy1")}
                          <span
                            className="methodMobile__content___privacyText2"
                            onClick={() => {
                              if (type === "CreditCard" && props.payChannel === "E-Comprocessing") {
                                window.open("https://www.smile.one/eu/message/about/reula");
                              } else {
                                setShowPolicy({ visable: true, isPrivacyPolicy: false });
                              }
                            }}
                          >
                            {t("privacy_policy2")}
                          </span>
                          {t("privacy_policy3")}
                          <span
                            className="methodMobile__content___privacyText2"
                            onClick={() => {
                              if (type === "CreditCard" && props.payChannel === "E-Comprocessing") {
                                window.open("https://www.smile.one/eu/message/about/rprivacy");
                              } else {
                                setShowPolicy({ visable: true, isPrivacyPolicy: true });
                              }
                            }}
                          >
                            {t("privacy_policy4")}
                          </span>
                          {t("privacy_policy5")}
                        </span>
                      </div>
                      <p className="home--error" style={{ display: form.isPolicyAccepted ? "none" : "block" }}>
                        {t("privacy_policy_error")}
                      </p>
                      {props.tradeInfo?.app_info?.region === "PER" && props.payChannel === "OpenPay" && (
                        <div className="methodMobile__content__support">
                          <div className="methodMobile__content__support__row">
                            <img src={MailImg} alt="" />
                            <p>support.latam@luxtak.com</p>
                          </div>
                          {/* <div className="methodMobile__content__support__row">
                            <img src={PhoneImg} alt="" />
                            <p>981197235</p>
                          </div> */}
                          <div className="methodMobile__content__support__row">
                            <img src={LocationImg} alt="" />
                            <p>Av. República de Panamá Of 403, Distrito San Isidro, Lima</p>
                          </div>
                        </div>
                      )}
                      <div className="methodMobile__content__support">
                        <div className="methodMobile__content__support__row" style={{ flexDirection: "column" }}>
                          <p style={{ textAlign: "center", padding: "0 10px" }}>{t("pci_notice")}</p>
                          <img src={PCI} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>
              </div>
            )}
          </FlipMove>
        </div>
      </Element>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    channelAgent: state.channel,
    channelList: state.channelList,
    tradeInfo: state.tradeInfo,
    paypalCreditCard: state.paypalCreditCard,
    promotions: state.promotions,
    loadedLibs: state.loadedLibs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeMethod: (method, channel, setIsLoading, handleError) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError)),
    setWalletChannels: (method, handleError, loading) => dispatch(setWalletChannels(method, handleError, loading)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
