import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import Transfiya from "../../../img/transfiya.png";
import "./CheckoutTransfiya.scss";

function CheckoutTransfiya() {
  const { t } = useTranslation();
  return (
    <div className="checkoutTransfiya">
      <CheckoutTitle />
      <div className="checkoutTransfiya__inst">
        <div className="checkoutTransfiya__logo">
          <img src={Transfiya} alt="" />
        </div>
        <ol>
          <li>{t("trsf1")}</li>
          <li>{t("trsf2")}</li>
          <li>{t("trsf3")}</li>
          <li>{t("trsf4")}XXXXXXXXXXX</li>
          <li>
            {t("trsf5")}
            <span className="checkoutTransfiya__inst--bold">
              <FormatMoney type="total" />
            </span>
            {t("trsf6")}
          </li>
        </ol>
      </div>
    </div>
  );
}

export default CheckoutTransfiya;
