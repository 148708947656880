import React, { useEffect, useState } from "react";
import { checkAccountNumber, checkBankList } from "../../../utils/validations";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { use } from "i18next";

const CssTextField = makeStyles((theme) => ({
  root: {
    backgroundColor: "#40465c",
    color: "#fff",
    //margin: "0 10px",
    borderRadius: "4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiFilledInput-input, .MuiInputLabel-filled": {
      color: "#fff",
    },

    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:hover": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "8px",
    },
  },
}));

function BankList({ form, setForm, setFocusOnInput = () => {}, region, type, mobile = false, ...props }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [channelList, setChannelList] = useState([]);
  const [search, setSearch] = useState("");
  const classes = CssTextField();

  // filter channelList by search
  useEffect(() => {
    if (search) {
      const filteredChannelList = props.channelList?.[props.method]?.filter((item) => {
        return item?.data?.[0]?.bank_name?.toLowerCase().includes(search.toLowerCase());
      });
      setChannelList(filteredChannelList);
    } else {
      setChannelList(props.channelList?.[props.method]);
    }
  }, [search]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        bank_list: { ...prev.bank_list, value: "", checked: false, valid: true },
      };
    });
  }, [props.method]);

  useEffect(() => {
    if (
      props.method === "BankTransfer" &&
      props.payChannel === "Localpayment" &&
      region &&
      form.account_number.checked
    ) {
      checkAccountNumber({ form: form, setForm: setForm, region: region });
    }
  }, [form.bank_list.value]);

  return (
    <>
      <Autocomplete
        className="homeMobile__filling__content__input"
        value={form.bank_list.value}
        onChange={(event, newValue) => {
          setForm((prev) => {
            return {
              ...prev,
              bank_list: { ...prev.bank_list, value: newValue || "" },
            };
          });
        }}
        options={props.channelList?.[props.method]?.map?.((item) => item?.data?.[0]) || []}
        getOptionLabel={(option) => option?.bank_name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t(type === "store" ? "store_list" : props.method === "Cash" ? "agent" : "bank_list")}
            variant="outlined"
            error={!form.bank_list.valid}
            helperText={
              !form.bank_list.valid &&
              t(type === "store" ? "store_select" : props.method === "Cash" ? "agent_select" : "bank_select")
            }
            size="small"
            inputProps={{ ...params.inputProps, autocomplete: "new-password" }}
          />
        )}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onOpen={() => {
          if (type === "popup") {
            setOpen(true);
            setChannelList(props.channelList?.[props.method]);
          }
        }}
        onBlur={() => {
          if (type === "popup") {
          } else {
            checkBankList({ form: form, setForm: setForm });
          }
        }}
      />

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            minWidth: "90%",
            maxWidth: "90%",
            backgroundColor: "#131820",
          },
        }}
      >
        <div style={{ backgroundColor: "#131820", color: "#fff", position: "relative", padding: "10px 15px" }}>
          <span
            onClick={() => setOpen(false)}
            style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
          >
            &#10005;
          </span>
          <h1 style={{ textAlign: "center", fontSize: "20px" }}>{t("select_bank")}</h1>
          <TextField
            label={t("search")}
            variant="filled"
            fullWidth
            className={classes.root}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              gap: "15px",
              marginTop: "20px",
            }}
          >
            {channelList?.map?.((item) => {
              return (
                <div
                  onClick={() => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        bank_list: { ...prev.bank_list, value: item?.data?.[0], valid: true, checked: true },
                      };
                    });
                    setOpen(false);
                  }}
                  key={item?.data?.[0]?.bank_id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "120px",
                    backgroundColor: "#40465c",
                    flexGrow: "1",
                    borderRadius: "4px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={item?.data?.[0]?.logo}
                    alt=""
                    style={{ height: "50px", backgroundColor: "#fff", borderRadius: "8px" }}
                  />
                  <p style={{ fontSize: "12px", textAlign: "center", marginBottom: "0" }}>
                    {item?.data?.[0]?.bank_name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    channelList: state.channelList,
    method: state.method,
    payChannel: state.payChannel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankList);
