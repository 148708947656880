export const SET_METHOD = "SET_METHOD";
export const SET_CHANNEL = "SET_CHANNEL";
export const SET_TRADE_INFO = "SET_TRADE_INFO";
export const SET_PAYMENT_INFO = "SET_PAYMENT_INFO";
export const SET_CHANNEL_LIST = "SET_CHANNEL_LIST";
export const SET_RETURN_URL = "SET_RETURN_URL";
export const SET_PREPAY_ID = "SET_PREPAY_ID";
export const SET_PAY_DATA = "SET_PAY_DATA";
export const SET_TRADE_STATUS = "SET_TRADE_STATUS";
export const SET_PAY_CHANNEL = "SET_PAY_CHANNEL";
export const SET_LOADED_LIBS = "SET_LOADED_LIBS";
export const SET_PAYPAL_CREDITCARD = "SET_PAYPAL_CREDITCARD";
export const SET_PAYPAL_WALLET = "SET_PAYPAL_WALLET";
export const SET_GEOLOCATION = "SET_GEOLOCATION";
export const SET_PROMOTIONS = "SET_PROMOTIONS";
export const SET_ISAPPLOADING = "SET_ISAPPLOADING";
export const SET_ALERT = "SET_ALERT";
export const SET_ISMOBILE = "SET_ISMOBILE";
