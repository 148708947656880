import React, { useEffect } from "react";
import Failed from "../Result/Failed";
import "./AuthorizationFail.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setTradeStatus } from "../../../redux/actions";

function AuthorizationFail(props) {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage("en");
    props.setTradeStatus({ id: "fail", text: "", notice: "Bank authorization failed, please try again." });
  }, []);

  return (
    <div className="authorizationFail">
      <Failed />
      <button
        onClick={() => {
          window.location.replace(document.referrer);
        }}
      >
        Return to Bank Login Page
      </button>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return { setTradeStatus: (item) => dispatch(setTradeStatus(item)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationFail);
