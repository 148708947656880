import React, { useEffect } from "react";
import req from "./utils/axios";
import queryUrl from "./utils/queryUrl";
import { connect } from "react-redux";
import { setTradeInfo, setPrepayId, queryOrder, setIsAppLoading, setTradeStatus } from "./redux/actions";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./components/Pages/Home/Home";
import Result from "./components/Pages/Result/Result";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Checkout from "./components/Pages/Checkout/Checkout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProcessPage from "./components/Pages/Static-Process/ProcessPage";
import Authorization from "./components/Pages/Authorization/Authorization";
import AuthorizationFail from "./components/Pages/Authorization-Fail/AuthorizationFail";
import NotFound from "./components/Pages/Not-Found/NotFound";
import MainSkeleton from "./components/Pages/Home/MainSkeleton";
import RefundConfirmation from "./components/Pages/Refund-Confirmation/RefundConfirmation";
import RequireAuth from "./components/Pages/RequireAuth";
import { handleErrorGlobal, notify } from "./utils/toast";
import AlertDialog from "./components/comp/AlertDialog";
import ViewChange from "./components/comp/ViewChange";

function App(props) {
  const prepayId = queryUrl("prepay_id");

  const handleError = (err, notice) => {
    handleErrorGlobal({ err: err, notice_code: notice, defaultErrCode: "fail_try_agin" });
  };

  useEffect(() => {
    props.setPrepayId(prepayId);
    const pathname = window.location.pathname;
    if (pathname === "/process" || pathname === "/authorization_fail") {
      // static pages
      props.setIsAppLoading(false);
    } else if (pathname === "/refund-confirmation" && prepayId) {
      req.post(`/api/trade/query-refund-key?prepay_id=${prepayId}`).then((res) => {
        if (res?.data?.code === "10000") {
          const tradeInfo = res?.data?.data;
          props.setTradeInfo({
            out_trade_no: tradeInfo.trade_no,
            order_currency: tradeInfo.refund_currency,
            order_amount: tradeInfo.refund_amount,
            pay_currency: tradeInfo.refund_currency,
            pay_amount: tradeInfo.refund_amount,
            app_info: {
              app_name: tradeInfo.app_name,
              region: "BRA",
            },
            content: "Refund confirmation",
          });
          props.setIsAppLoading(false);
        } else if (res.data?.code === "40002" && res.data?.sub_code === "refund-time-out") {
          props.setTradeStatus({
            id: "process",
            text: "refund_verifying", // i18n id
            notice: "refund_in_process", // i18n id
          });
          props.setIsAppLoading(false);
        } else {
          handleError(`unknown error code: ${res.data.code}`);
        }
      });
    } else if (prepayId) {
      ///
      props.queryOrder(prepayId);
    } else {
      notify("Missing parameters!");
    }
  }, []);
  return (
    <>
      <div className="App">
        <ViewChange />
        <Router>
          <Header />
        </Router>
        {props.isAppLoading && (
          <>
            <ToastContainer />
            <MainSkeleton />
          </>
        )}
        {!props.isAppLoading && (
          <Router>
            <ToastContainer />
            <AlertDialog />
            <Switch>
              <Route exact path="/">
                <RequireAuth>
                  <Home />
                </RequireAuth>
              </Route>
              <Route path="/checkout">
                <RequireAuth>
                  <Checkout />
                </RequireAuth>
              </Route>
              <Route path="/result">
                <RequireAuth>
                  <Result />
                </RequireAuth>
              </Route>
              <Route path="/process">
                <ProcessPage />
              </Route>
              <Route path="/authorization_fail">
                <AuthorizationFail />
              </Route>
              <Route path="/authorization">
                <Authorization />
              </Route>
              <Route path="/refund-confirmation">
                <RequireAuth>
                  <RefundConfirmation />
                </RequireAuth>
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    isAppLoading: state.isAppLoading,
    alert: state.alert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
    setPrepayId: (item) => dispatch(setPrepayId(item)),
    queryOrder: (prepayId) => dispatch(queryOrder(prepayId)),
    setIsAppLoading: (item) => dispatch(setIsAppLoading(item)),
    setTradeStatus: (item) => dispatch(setTradeStatus(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
