import React from "react";
import "./Processing.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import LottiAnimation from "../../comp/LottiAnimation";
function Processing(props) {
  const { t } = useTranslation();
  return (
    <div className="processing">
      <div className="processing__loader">
        <LottiAnimation anime="loading" height={100} />
      </div>
      <p className="processing__notice1">{props.tradeStatus?.text ? t(props.tradeStatus?.text) : t("processing")}</p>
      <p className="processing__notice2">
        {props.tradeStatus?.notice ? t(props.tradeStatus?.notice) : t("processing_notice")}
      </p>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeStatus: state.tradeStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Processing);
